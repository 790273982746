import React from 'react';

import Layout from '../components/Layout/Layout';
import Article from '../components/Article/Article';
import Repo from '../components/Repo/Repo';
import Jobs from '../components/Jobs/Jobs';

import { getImageFromContent } from '../lib/content.utils';

import { pageConfig } from '../../config/index.config';
import GithubData from '../../data/github.json';
import MediumData from '../../data/medium.json';

function isNotCerberusSubProject(repo) {
  return repo.name.toLowerCase().indexOf('cerberus-') <= -1;
}

const filteredRepos = GithubData.filter(isNotCerberusSubProject)
  .sort((a, b) => {
    if(a.stargazers_count < b.stargazers_count) { return 1; }
    if(a.stargazers_count > b.stargazers_count) { return -1; }
    return 0;
  });

const IndexPage = () => {
  return (
    <Layout>
      <div className="ncss-row">
        <div className="ncss-col-sm-12">
          <h1 className="engr-header-3 mt4-sm mb4-sm border-bottom" data-testid="test-header-about">About NIKE Global Technology</h1>
          <div className="" data-testid="test-body-about">
            NIKE is a technology company. From our flagship website and five-star mobile apps to developing products,managing big data and providing leading-edge engineering and systems support, our teams at NIKE Global Technology exist to revolutionize the future at the confluence of tech and sport. We invest in and develop advances in technology and employ the most creative people in the world, and then give them the support to constantly innovate, iterate and serve consumers more directly and personally.  Our teams are innovative, diverse, multidisciplinary and collaborative, taking technology into the future and bringing the world with it.
          </div>
        </div>
      </div>
      <div className="ncss-row d-xl-flx flx-dir-xl-rr">

        <div className="ncss-col-sm-12 ncss-col-lg-12 ncss-col-xl-7 ncss-col-xl-8 va-sm-t=">
          <div className="ncss-container prl0-sm">
            <div className="ncss-row mt12-sm">
              <div className="ncss-col-sm-12">
                <h1 className="engr-header-3" data-testid="test-header-article">Recent Articles</h1>
              </div>
            </div>
            <div className="ncss-row" data-testid="test-list-article">
              {MediumData.items.slice(0, pageConfig.maxArticles).map((item, idx) => (
                <Article
                  key={item.created}
                  title={item.title}
                  author={item.dc_creator}
                  image={getImageFromContent(item.content_encoded)}
                  url={item.link}
                  index={idx}
                />
              ))}
            </div>
            <div className="ncss-row mt12-sm">
              <div className="ncss-col-sm-12">
                <h1 className="engr-header-3" data-testid="test-header-repo">Featured Open Source Projects</h1>
              </div>
            </div>
            <div className="ncss-row" data-testid="test-list-repo">
              {filteredRepos.slice(0, pageConfig.maxRepos).map((item, idx) => (
                <Repo
                  key={item.id}
                  description={item.description}
                  title={item.name}
                  url={item.html_url}
                  stars={item.stargazers_count}
                  lang={item.language}
                  image={`${item.name.toLowerCase()}_logo.png`}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="ncss-col-sm-12 ncss-col-lg-12 ncss-col-xl-5 ncss-col-xl-4 va-sm-t pr8-xl">
          <div className="ncss-container prl0-sm">
            <Jobs />
          </div>
        </div>

      </div>

    </Layout>
  );
};

export default IndexPage;
