import React from 'react';

import './style.css';

const Loader = () => {
  return (
    <div className="progressContainer mt4-sm" data-testid="test-loader-wrapper">
      <div className="progressBar" />
    </div>
  );
};

export default Loader;
