import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Header from '../Header/Header';
import Head from '../Head/Head';
import Nav from '../Nav/Nav';
import Footer from '../Footer/Footer';

import './style.css';

const Layout = ({children}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          siteUrl
          copyright
          navigation {
            id
            label
            url
          }
          social {
            twitter
            fbAppId
          }
        }
      }
    }
  `);

  return (
    <div className="ncss-container" data-testid="test-layout-wrapper">
      <Head title={data.site.siteMetadata.title} description={data.site.siteMetadata.description} />
      <Nav
        siteTitle={data.site.siteMetadata.title}
        links={data.site.siteMetadata.navigation}
      />
      <Header />
      <div className="ncss-row mb10-sm">
        <div className="ncss-col-sm-12">
          <div className="ncss-container fixed-fluid">
            {children}
          </div>
        </div>
      </div>
      <Footer copyright={data.site.siteMetadata.copyright} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
