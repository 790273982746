import React from 'react';
import './style.css';

const Header = () => {
  const bgImage = {
    backgroundImage: `url('../images/pre-hall.jpg')`
  };
  return (
    <div className="ncss-row" data-testid="test-header-wrapper">
      <div className="ncss-col-sm-12 full">
        <div className="outer-bar d-sm-flx">
          <header
            className="bar-header"
            style={bgImage}
            data-testid="test-header"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
