import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

function getClasses(idx) {
  switch (idx) {
    case 0:
      return {
        size: 'hero',
        grid: 'ncss-col-sm-12 mt4-sm item'
      };
    case 1:
    case 2:
      return {
        size: 'lvl1',
        grid: 'ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-6 ncss-col-xl-6 mt4-sm item'
      };
    default:
      return {
        size: 'lvl2',
        grid: 'ncss-col-sm-12 ncss-col-md-4 ncss-col-lg-4 ncss-col-xl-4 mt4-sm item'
      };
  }
}

const Article = ({title, author, image, index, url}) => {
  const bgImage = {
    backgroundImage: `url('${image}')`
  };
  const classes = getClasses(index);
  const classNames = `border ${classes.size} img-wrapper`;
  return (
    <div className={classes.grid} data-testid="test-article-wrapper">
      <div className="ta-sm-l">
        <div
          className={classNames}
          style={bgImage}
          data-testid="test-article-card"
        >
          <a href={url}><div className="u-a11y-h">{`Visit ${title}`}</div></a>
        </div>
        <a href={url}>
          <h4
            className="headline-4 text-color-primary-dark mt2-sm"
            data-testid="test-article-title"
          >
            {title}
          </h4>
        </a>
        <p
          className="body-3 text-color-secondary mt2-sm"
          data-testid="test-article-author"
        >
          {author}
        </p>
      </div>
    </div>
  );
};

Article.propTypes = {
  title: PropTypes.string,
  author: PropTypes.string,
  image: PropTypes.string,
  index: PropTypes.number,
  url: PropTypes.string
};

Article.defaultProps = {};

export default Article;
