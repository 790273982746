import React, { Component } from 'react'
import Loader from '../Loader/Loader';
import './style.css';

function getBrandIcon(brand) {
  switch (brand.toLowerCase()) {
    case 'converse':
      return 'g72-converse';
    case 'hurley':
      return 'g72-hurley';
    case 'jordan':
      return 'g72-jordan';
    default:
      return 'g72-swoosh';

  }
}

function getClasses(idx) {
  let striped = '';
  if (idx % 2) {
    striped = 'bg-primary-grey';
  }
  return `border-top pt4-sm pb4-sm pl4-sm pr4-sm d-sm-flx flx-dir-sm-r ${striped}`;
}

class Jobs extends Component {
  state = {
    loading: false,
    error: false,
    jobs: [],
  };

  componentDidMount() {
    this.fetchJobs()
  }

  render() {
    return (
      <div className="ncss-row mt12-sm">
        <div className="ncss-col-sm-12 job-section">
          <h1 className="engr-header-3" data-testid="test-jobs-title">Latest Tech Jobs</h1>
          <div>
            {this.state.loading && <Loader data-testid="test-jobs-loader" />}
            <ul className="mt4-sm border-bottom" data-testid="test-jobs-list">
            {this.state.jobs.slice(0,15).map((item, idx) => (
              <li className={getClasses(idx)} key={item.atsId} data-testid="test-jobs-item">
                <a className="d-sm-iflx" href={item.postUrl}>
                  <div className="job-thumb-container va-sm-t d-sm-h d-md-b" style={{backgroundImage: `url('https://jobs.nike.com${item.image}')`}}/>
                  <div className="job-info-container va-sm-t">
                    <p className="job-info-brand body-3 mb2-sm"><i className={getBrandIcon(item.atsPayload.brand)} /></p>
                    <h4 className="job-info-title mb2-sm" dangerouslySetInnerHTML={{__html: item.atsPayload.content.title}} />
                    <p className="job-info-location body-4 mb2-sm" dangerouslySetInnerHTML={{__html: `${item.atsPayload.location.administrational.city}, ${item.atsPayload.location.administrational.stateProvince}`}} />
                  </div>
                </a>
              </li>
            ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }

  // This data is fetched at run time on the client.
  fetchJobs = () => {
    this.setState({ loading: true });
    fetch('https://nike-engr-jobs-dev.s3-us-west-2.amazonaws.com/results.json')
      .then(response => response.json())
      .then(data => {
        this.setState({
          loading: false,
          jobs: data.jobs,
        })
      })
      .catch(error => {
        this.setState({ loading: false, error })
      })
  }
}

export default Jobs;
