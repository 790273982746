import React, {useState} from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Nav = ({siteTitle, links}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="ncss-row nav" data-testid="test-nav-wrapper">
      <div className="ncss-col-sm-12 full">
        <div className="inkbar" />
        <div className="sub-menu d-sm-flx flx-jc-sm-sb flx-ai-sm-c">
          <div className="sub-menu-brand d-sm-iflx">
            <i className="logo g72-swoosh" />
            <h1 title={siteTitle} className="title" data-testid="test-nav-title">
              {siteTitle}
            </h1>
          </div>
          <div
            className="d-md-h d-sm-iflx text-color-primary-light"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <i className={`${isMenuOpen ? 'g72-minus' : 'g72-menu'}`} />
          </div>
          <div
            className={`sub-menu-links d-sm-h d-md-iflx ${isMenuOpen ? 'toggled-open' : ''}`}
            data-testid="test-nav-links"
          >
            {links.map(link => (
              <a key={link.id} className="sub-menu-link" href={link.url}>{link.label}</a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

Nav.propTypes = {
  siteTitle: PropTypes.node,
  links: PropTypes.arrayOf(PropTypes.object)
};

Nav.defaultProps = {
  links: []
};

export default Nav;
