import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const Head = ({title, description}) => {
  return (
    <>
      <Helmet>
        <html lang="en" />

        <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
        <meta
          content="width=device-width,initial-scale=1.0,user-scalable=yes"
          name="viewport"
        />

        <meta content={title} name="apple-mobile-web-app-title" />
        <meta content={title} property="og:title" />
        <meta content={title} name="twitter:title" />
        <title>{title}</title>

        <meta content={description} name="description" />
        <meta content={description} property="og:description" />
        <meta content={description} name="twitter:description" />

        <link rel="stylesheet" href="//www.nike.com/assets/ncss/glyphs/2.5/css/glyphs.min.css" />
        <link rel="stylesheet" href="//www.nike.com/assets/ncss/3.0/dotcom/desktop/css/ncss.en-us.min.css" />

      </Helmet>
    </>
  )
};

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};


export default Head;
