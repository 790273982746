import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

function withFallback(img) {
  try {
    return require(`../../images/icons/${img}`);
  } catch (e) {
    if (e.name !== "ModuleNotFoundError") {
      return require('../../images/icons/nike_github_logo.png');
    }
  }
}

const btnSmall = {
  paddingTop: '6px',
  paddingBottom: '6px',
  paddingLeft: '14px',
  paddingRight: '14px',
  borderRadius: '14px',
};

const Repo = ({ description, title, url, image, stars, lang }) => {
  return (
    <div
      className="ncss-col-sm-12 ncss-col-md-6 ncss-col-lg-6 ncss-col-xl-4 full mt4-sm item"
      data-testid="test-repo-wrapper"
    >
      <div className="ncss-row p2-sm">
        <div className="ncss-col-sm-12 full repo-container border">
          <div className="ncss-row pt2-sm prl4-sm repo-header">
            <div className="icon d-sm-flx">
              <a href={url}>
                <img alt="project icon" src={withFallback(image)} />
              </a>
              <a href={url}>
                <h4 className="headline-4 text-color-primary-dark mt2-sm">
                  {title}
                </h4>
              </a>
            </div>
          </div>
          <div className="ncss-row prl4-sm repo-body">
            <p className="body-3 text-color-secondary mt2-sm truncated">{description}</p>
          </div>
          <div className="ncss-row repo-footer border-top">
            <div className="d-sm-flx flx-wr-sm-nw flx-jc-sm-sa u-full-height">
              <div className="info-label flx-as-sm-c flex-item">
                <a href={url} className="ncss-btn-primary-dark" style={btnSmall}>Github</a>
              </div>
              <div className="info-label flx-as-sm-c flex-item">
                {lang}
              </div>
              <div className="info-label flx-as-sm-c flex-item">
                <i className="g72-star mr1-sm text-color-accent" />{stars}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

Repo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  stars: PropTypes.number,
  lang: PropTypes.string,
};

export default Repo;
