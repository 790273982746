import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Footer = ({copyright}) => (
  <div className="ncss-row" data-testid="test-footer-wrapper">
    <div className="ncss-col-sm-12 full">
      <footer
        className="d-sm-flx p4-sm ta-sm-l va-sm-m"
        data-testid="test-footer-element"
      >
        © {new Date().getFullYear()} {copyright}
      </footer>
    </div>
  </div>
);

Footer.propTypes = {
  copyright: PropTypes.string
};

export default Footer;
